import { useEffect } from 'react';
import { useLocalStorage } from 'src/hooks';
import { useRouter } from 'next/router';
import styles from './GuestLayout.module.scss';
import { LoadingIndicator } from 'src/components';
import { useHasMounted } from 'src/utils/hooks/useHasMounted';

export default function GuestLayout(props) {
  const router = useRouter();
  const hasMounted = useHasMounted();
  const [accessToken] = useLocalStorage('access_token', '');

  useEffect(() => {
    if (accessToken && hasMounted) {
      const isUnsubscribe = router.pathname === '/accounts/unsubscribe';
      setTimeout(() => {
        router.push(
          isUnsubscribe ? '/user-settings?source=unsubscribe' : '/search'
        );
      }, 500);
    }
  }, [accessToken, hasMounted]);

  if (accessToken && hasMounted) {
    return (
      <div style={{ height: '100vh' }}>
        <LoadingIndicator />
      </div>
    );
  }

  return <div className={styles.container}>{props.children}</div>;
}
