import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';
import { useQuery, UseQueryResult } from 'react-query';
import { request } from '../request';
import { useAuth } from 'src/context';

const QUERY_KEYS = {
  status_list: 'status_list',
  data_sources_info: 'data_sources_info',
};

export function useGetMyStatuses(): UseQueryResult<{
  status_options: { name: string; value; color: string }[];
}> {
  const { user } = useAuth();

  return useQuery(
    [QUERY_KEYS.status_list, user?.company?.id],
    () => {
      const queryParams = getSearchQueryParamsForAPI({
        company_id: user?.company?.id,
      });

      return request({
        url: `/v1/prospect-list/config${queryParams}`,
        data: {},
        method: 'GET',
      });
    },
    {
      enabled: true,
      cacheTime: 10000,
    }
  );
}

export function useGetDataSourcesInfo(enabled) {
  return useQuery(
    [QUERY_KEYS.data_sources_info],
    () => {
      const queryParams = getSearchQueryParamsForAPI({});

      return request({
        url: `/v1/meta/data-sources${queryParams}`,
        data: {},
        method: 'GET',
      });
    },
    {
      enabled: Boolean(enabled),
      refetchOnWindowFocus: false,
      cacheTime: 600000,
    }
  );
}
