/* eslint-disable @next/next/no-img-element */
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { LoadingIndicator } from 'src/components';
import { useHasMounted } from 'src/utils/hooks/useHasMounted';
import { useAuth } from 'src/context';

function TearoffLayout({ children }) {
  const router = useRouter();
  const hasMounted = useHasMounted();
  const { logout, user } = useAuth();

  // Ensure authorized user
  useEffect(() => {
    if (user || !hasMounted) return;

    (async () => {
      const returnTo = await logout();
      router.replace(returnTo);
    })();
  }, [user, hasMounted, logout, router]);

  if (!user && hasMounted) {
    return (
      <div style={{ height: '100vh' }}>
        <LoadingIndicator />
      </div>
    );
  }

  if (!hasMounted) return null;

  // logo utilizing an <a></a> tag to force app/page reload whenever clicked
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        height: '100%',
        width: '100%',
      }}
    >
      {children}
    </div>
  );
}

export default TearoffLayout;
