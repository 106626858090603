import { ToastContainer, Toast, Button } from 'react-bootstrap';
import { Z_INDEX } from 'src/utils/constants/scss-variables.constants';

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <ToastContainer
      position="top-end"
      className="p-3"
      style={{ marginTop: 60, zIndex: Z_INDEX.toastContainer }}
    >
      <Toast show={!!error} onClose={resetErrorBoundary} autohide={false}>
        <Toast.Header>
          <strong className="me-auto text-danger">There was an error</strong>
          <small className="text-muted">just now</small>
        </Toast.Header>
        <Toast.Body>
          <span className="text-danger">
            {error.message || 'Something unexpected happened.'}
          </span>
          <div className="d-flex justify-content-end">
            <Button onClick={resetErrorBoundary} variant="danger">
              Reload
            </Button>
          </div>
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default ErrorFallback;
