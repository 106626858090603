import { useState, useEffect, useMemo } from 'react';
import { request } from 'src/api/request';
import _ from 'lodash';
import { Spinner } from 'react-bootstrap';
import {
  useGetTerritories,
  useGetRepProspectLists,
  useGetProviderLists,
} from 'src/hooks';
import { useGetMyProspectLists } from 'src/api/prospects/prospect-lists';

import { TabSelector } from 'src/components';
import { useLogging, useAuth, useMap } from 'src/context';
import { useTheme, Box, styled, Typography } from '@mui/material';
import { Checkbox } from '@mui/joy';
import { grey } from '@mui/material/colors';
import { useFlags } from 'launchdarkly-react-client-sdk';

const RepListGroupHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingLeft: '0.8rem',
  paddingRight: '1.1rem',
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
  backgroundColor: grey[100],
  fontWeight: 600,
  borderTop: `1px solid ${grey[300]}`,
  borderBottom: `1px solid ${grey[300]}`,
}));

function RepsTerritoriesAndLists({
  viewSource = 'managerOverview',
  onProspectListsFetched = _.noop,
  onTabSelected = _.noop,
  activeTab = null,
  tabOptions = null,
}) {
  const { useNewListsModule } = useFlags();
  const { user } = useAuth();
  const log = useLogging();

  const [checkedTerritories, setCheckedTerritories] = useState([]);
  const [checkedLists, setCheckedLists] = useState([]);

  const { existingTerritories, setExistingTerritories, editingTerritory } =
    useMap();

  const theme = useTheme();

  // new calls
  const { data: territories, isLoading: isTerrioriesLoading } =
    useGetTerritories({
      pageSize: 1000,
    });
  const { data: pipelines, isLoading: isProspectListsLoading } =
    useGetMyProspectLists();
  const { data: providerLists, isLoading: isProviderListsLoading } =
    useGetProviderLists();

  const groupedTerritories = useMemo(() => {
    // group territories by owner
    const grouped = territories?.results?.reduce((acc, territory) => {
      if (!acc[territory.owner.id]) {
        acc[territory.owner.id] = {
          ownerId: territory.owner.id,
          ownerName: `${territory.owner.first_name} ${territory.owner.last_name}`,
          territories: [],
        };
      }
      acc[territory.owner.id].territories.push(territory);
      return acc;
    }, {});

    // convert grouped territories object to array of objects
    const groupedArray = grouped ? Object.values(grouped) : [];

    // Find the index where user.id matches the owner id
    const userTerritoriesIndex = groupedArray.findIndex(
      (group: any) => group.ownerId === user.id
    );

    // If found, move it to the top
    if (userTerritoriesIndex !== -1) {
      const userTerritories = groupedArray.splice(userTerritoriesIndex, 1);
      groupedArray.unshift(...userTerritories);
    }

    return groupedArray;
  }, [territories]);

  const listsOrPipelines = useMemo(() => {
    return useNewListsModule ? providerLists?.results : pipelines?.lists;
  }, [useNewListsModule, providerLists, pipelines]);

  const {
    data: prospectLists,
    isFetching,
    isLoading,
  } = useGetRepProspectLists(checkedLists);

  const loading =
    isLoading ||
    isFetching ||
    isTerrioriesLoading ||
    isProspectListsLoading ||
    isProviderListsLoading;

  useEffect(() => {
    if (!existingTerritories?.length) return;

    const checkedTerritories = existingTerritories.map((t) => t.id);
    setCheckedTerritories(checkedTerritories);
  }, []);

  useEffect(() => {
    if (!prospectLists || loading) return;
    getSelectedProspectLists();
  }, [prospectLists, loading]);

  useEffect(() => {
    if (!checkedTerritories) return;
    getSelectedTerritories();
  }, [checkedTerritories]);

  function toggleTerritory(territory) {
    if (checkedTerritories.includes(territory.id)) {
      setCheckedTerritories(
        checkedTerritories.filter((t) => t !== territory?.id)
      );

      const newTerritories = existingTerritories.filter(
        (t) => t.id !== territory.id
      );
      setExistingTerritories(newTerritories);
    } else {
      const newTerritories = [...checkedTerritories, territory?.id];
      setCheckedTerritories(newTerritories);
    }
  }

  function toggleProspectList(prospectList) {
    const newCheckedValue = !checkedLists.includes(prospectList?.id);
    log.event(`${viewSource}ProspectListToggled`, {
      id: prospectList.id,
      value: newCheckedValue,
    });

    if (checkedLists.includes(prospectList?.id)) {
      setCheckedLists(checkedLists.filter((t) => t !== prospectList?.id));
    } else {
      const newTerritories = [...checkedLists, prospectList?.id];
      setCheckedLists(newTerritories);
    }
  }

  async function getSelectedTerritories() {
    if (!checkedTerritories) return [];
    for (const territory of checkedTerritories) {
      const found = existingTerritories.find((t) => t.id === territory);
      if (!found) {
        const newTerritory = await request({
          url: `v1/accounts/territories/${territory}`,
          data: {},
          method: 'GET',
        });
        const color = theme.palette.territory[existingTerritories.length]?.main;
        newTerritory.color = color;
        setExistingTerritories([...existingTerritories, newTerritory]);
      }
    }
  }

  async function getSelectedProspectLists() {
    const formattedWithRepInfo = _.reduce(
      prospectLists?.lists,
      (result, list) => {
        return [
          ...result,
          ..._.map(list.prospects, (prospect) => {
            return {
              user_name: list.user_name,
              user_id: list.user_id,

              ...prospect,
            };
          }),
        ];
      },
      []
    );

    onProspectListsFetched(formattedWithRepInfo);
  }

  const showTabSelector = onTabSelected && tabOptions && activeTab;

  return (
    <>
      {showTabSelector && (
        <Box sx={{ margin: '0 0.6rem 0.6rem 0.6rem' }}>
          <TabSelector
            onTabChange={onTabSelected}
            value={activeTab}
            tabs={tabOptions}
          />
        </Box>
      )}

      <RepListGroupHeader>
        Your Team {loading && <Spinner animation="border" size="sm" />}
      </RepListGroupHeader>

      <Box sx={{ padding: '1rem 0.5rem' }}>
        {groupedTerritories?.map((territory: any) => {
          const lists = listsOrPipelines?.filter((list) => {
            return list.owner.id === territory.ownerId;
          });
          return (
            <Box
              key={territory?.ownerId}
              sx={{
                marginBottom: '0.5rem',
                padding: '0.5rem',
                borderTop: '1px solid #e0e0e0',
                '&:first-child': {
                  borderTop: 'none',
                  paddingTop: 0,
                },
              }}
            >
              <Typography variant="subtitle2">
                {territory?.ownerName}
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="subtitle1">
                  {useNewListsModule ? 'Lists' : 'Pipelines'}
                </Typography>
                {lists?.length > 0 ? (
                  lists?.map((list) => {
                    return (
                      <Box key={list.id} sx={{ paddingLeft: '0.5rem' }}>
                        <Checkbox
                          key={list.id}
                          size="sm"
                          label={list.name}
                          checked={checkedLists.includes(list.id)}
                          onChange={() => toggleProspectList(list)}
                        />
                      </Box>
                    );
                  })
                ) : (
                  <Typography variant="caption">
                    No {useNewListsModule ? 'Lists' : 'Pipelines'}
                  </Typography>
                )}

                <Typography variant="subtitle1">Territories</Typography>
                {territory?.territories?.length > 0 ? (
                  territory?.territories?.map((t) => {
                    const isEditing = editingTerritory?.id === t.id;
                    const label = isEditing ? `${t.name} (editing)` : t.name;
                    return (
                      <Box key={t.id} sx={{ paddingLeft: '0.5rem' }}>
                        <Checkbox
                          key={t.id}
                          size="sm"
                          label={label}
                          checked={
                            checkedTerritories.includes(t.id) || isEditing
                          }
                          onChange={() => toggleTerritory(t)}
                          disabled={isEditing}
                        />
                      </Box>
                    );
                  })
                ) : (
                  <Typography variant="caption">No Territories</Typography>
                )}
              </Box>
            </Box>
          );
        })}
      </Box>
    </>
  );
}

export default RepsTerritoriesAndLists;
